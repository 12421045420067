import React from 'react';
import axios from 'axios';
import moment from 'react-moment';
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';

export const setLoading  = (status) => { 

	return { 
		type: types.BUTTON_LOADING,
		payload: status 
	}
};

export const setLogin = (obj,step) => {

	return {
		type: types.SET_LOGIN,
		payload: obj
	}	

};

export const setLogoff = (obj,step) => {

	return {
		type: types.SET_LOGOFF,
		payload: obj
	}	

};

export const processLogOff = (props) => {
	
		return dispatch => {
			
			// Set logoff flag
			sessionStorage.setItem("loggedOff",1);
			
			// Remove userData 
			sessionStorage.removeItem("@motuum/userData");	

			dispatch(setLogin(false));
			dispatch(setLogoff(true));		
													
			return;			
			
		} // end dispatch
	
};


export const processLogin = (data,props) => {
	
		return dispatch => {

				// Prepare for login
				const preparedData = {};
				
				// Set general data
				preparedData.email = data.email ? data.email:"";
				preparedData.password = data.password;

				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/manager/login',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
						Accept: 'application/json'
					}
				})				
				
				.then((response) => {
	
				// If an error occured
				if (response.data.error) {					
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setDialog(true);
					return;			
				} // end if	
	
  
				if (response.data.success) {
					
					props.setLoading(false);;
					
					// If company already exist
					if (response.data.noResults) {
						props.setDialogMessage('Não foi possível autenticar o seu usuário. Verifique o email e a senha informados.');
						props.setDialog(true);						
					} // end if
					
					
					// If company already exist
					if (response.data.token) {
						
						// Check if there is a local USER OBEJECt with a TOKEN
						var userObj = {
							userToken:response.data.token,
							profile:response.data.profile
						}
						const saveObj = JSON.stringify(userObj);
										
						// save User Data on Local Storage
						sessionStorage.setItem("@motuum/userData",saveObj);
						
						// Check of user activated profile
							//  if not, redirect to activation...
						
						// Navigate to next step
						props.history.push("/admin");
						return;
																
					} // end if
					
					
				} // end if
					
				}, (error) => {
															
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setDialog(true);					
										
				})
				.catch(error=> {
					
					console.log("error: "+JSON.stringify(error.message));
	
					props.setLoading(false);;
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');	
					props.setDialog(true);					

				});

			
		} // end dispatch
	
};





// APP REDUCER 
export const BUTTON_LOADING = "app/button/loading";
export const SET_DIALOG = "app/set/dialog";
export const SET_APPLOADING = "app/bootstrap/loading";
export const SET_APPSTART = "app/set/start";
export const SET_USERDATA = "app/set/user/data";
export const APP_LOADING = "app/set/loading";
export const INNER_LOADING = "app/set/inner/loading";
export const SET_CURRENT_FILTER = "app/set/current/list/filter";

// PROVIDERS
export const SET_PROVIDERS = "provider/set/providers/list";
export const SET_PROVIDER = "provider/set/provider/view";

// CUSTOMERS
export const SET_CUSTOMERS = "provider/set/customers/list";
export const SET_CUSTOMERS_INACTIVE = "provider/set/customers/inactive/list";
export const SET_CUSTOMER = "provider/set/customer/view";

// SERVICE ORDERS
export const SET_SERVICE_ORDERS = "service/set/orders/list";
export const SET_SERVICE_ORDER = "service/set/order/retrieve";


// LOGIN
export const SET_LOGIN = "manager/set/login";
export const SET_LOGOFF = "manager/set/logoff";


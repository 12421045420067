import * as types from '../actionTypes';

const initialState = { 
  services: [],
  service: {},
  appLoading: true
}
 
export const serviceReducer = (state = initialState, action) => {
		
    switch (action.type) {
		case types.APP_LOADING:
			return { ...state, appLoading: action.payload };		
		case types.SET_SERVICE_ORDERS:
			return { ...state,services: action.payload };
		case types.SET_SERVICE_ORDER:
			return { ...state,service: action.payload };
        default:
            return state
    }
}
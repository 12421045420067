import * as types from '../actionTypes';

const initialState = { 
	dialog:{}
}
 
export const appReducer = (state = initialState, action) => {
		
    switch (action.type) {	
        case types.SET_DIALOG:
            return { ...state, dialog: action.payload }	
        default:
            return state
    }
}
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import jwt_decode from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';


export const setAppLoading = (value) => ({
  type: types.APP_LOADING,
  payload: value
});

export const setServiceOrders = (value) => ({
  type: types.SET_SERVICE_ORDERS,
  payload: value
});

export const setServiceOrder = (value) => ({
  type: types.SET_SERVICE_ORDER,
  payload: value
});

export const retrieveServiceOrders = (data,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  

					// Prepare Params
					var preparedData = {}
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
				
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/service/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					}) 						
					
					.then((response) => {

						
						// Retrieve data to controller
						props.setServiceOrdersRows(response.data.services);
						props.setTotalRecords(response.data.count);
						
						// set rowCount first
						props.setLoading(false);
						return;
						
					 })
					.catch(error=> {
						props.setLoading(false);			
						props.setServiceOrdersRows();
						console.log(error);
						return;
					});		
					
		}
}

export const retrieveServiceOrder = (id,props) => {
	
		return dispatch => {
			
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = sessionStorage.getItem("@motuum/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
					// Prepare Params
					var preparedData = {}
					preparedData.serviceOrderId =  id;
												
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+"/api/v1/service/retrieve",
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
					})
					
					.then((response) => {
		
						dispatch(setServiceOrder(response.data.service));
						
						var ProviderUserId = response.data.service.ProviderUser ? response.data.service.ProviderUser.id : null;
						var avatar = response.data.service.ProviderUser ? response.data.service.ProviderUser.ProviderProfile.avatar:null;
						var avatarImageUrl = config.API_URL+"/provider/avatar/retrieve/"+ProviderUserId+"/"+avatar+"";
						props.setAvatar(avatarImageUrl);		

						// Set Status History
						props.setStatusHistory(response.data.service.ServiceOrderStatusHistories);
						
						// Set Payment History
						props.setPaymentHistory(response.data.service.ServiceOrderPaymentHistories);
						
						// Set Payment Tax History
						props.setPenaltyTaxes(response.data.service.ServiceOrderPaymentPenaltyTaxes);						
					

						const finalAddress = response.data.service.ServiceOrderAddress.streetType+" "+response.data.service.ServiceOrderAddress.streetName+", "+response.data.service.ServiceOrderAddress.streetNumber+", "+response.data.service.ServiceOrderAddress.suburb+", "+response.data.service.ServiceOrderAddress.city+", "+response.data.service.ServiceOrderAddress.state;
				
						 const googleMapsUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${finalAddress}&zoom=13&size=400x250&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyCAHF7IiEvbJkvOdX5eqwAkQqD0IXvqSN4`;
						 props.setMapImageUrl(googleMapsUrl);						
						
						// Turn array to flat
						const finalObj = response.data.service;
						props.setServiceOrderCol(finalObj);	
						props.setContentVisible(true);	
						return;						
						
					 })
					.catch(error=> {	
						props.setServiceOrderCol({});						
						console.log(error);
						return;						
					});		
					
		}
}


import * as types from '../actionTypes';

const initialState = { 
  buttonLoading: true,
  customers: [],
  customersInactive: [],
  customer: {},
  currentFilter:null,
  appLoading: true,	
  dialog:{},
}
 
export const customerReducer = (state = initialState, action) => {
		
    switch (action.type) {
		case types.APP_LOADING:
			return { ...state, appLoading: action.payload };
        case types.SET_DIALOG:
            return { ...state, dialog: action.payload }	
		case types.SET_CUSTOMERS:
			return { ...state,customers: action.payload };
		case types.SET_CUSTOMERS_INACTIVE:
			return { ...state,customersInactive: action.payload };			
		case types.SET_CUSTOMER:
			return { ...state,customer: action.payload };
		case types.SET_CURRENT_FILTER:
			return { ...state,currentFilter: action.payload };			
        default:
            return state
	}
			
}
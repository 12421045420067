import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ServiceActions from 'Store/Actions/Service'

const useStyles = makeStyles(styles);

const ServiceOrders = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

	
		
// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Gestão de Serviços</Typography>
	  <Typography color="textPrimary">Ordens de Serviço</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Cadastro de Ordens de Serviço</h3>
	<h5>Ordens de serviço ordenadas por padrão por mais recentemente criada. Se desejar filtre ou ordene a busca para encontrar uma ordem de serviço específica. </h5>	
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>
	  
		  <Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
		  >
			<Tab label="Válidas" />
			<Tab label="Canceladas" />
		  </Tabs>
     	  <Divider />
		  
			<TabPanel value={value} index={0}>
			
					<Tab1Content
						setAppLoading={setAppLoading}
						setContentVisible={setContentVisible}
					/>
					
			</TabPanel>
				  
			<TabPanel value={value} index={1}>
										
					<Tab2Content
						setAppLoading={setAppLoading}
						setContentVisible={setContentVisible}
					/>
					
			</TabPanel>		
	   
	  </GridItem>
	  
    </GridContainer>
	</>
  );
}

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);


// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {


// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveServiceOrders
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [serviceOrdersRows, setServiceOrdersRows] = useState([]);

// Filter fields
const [filterByService, setFilterByService] = useState("");
const [filterByCustomer, setFilterByCustomer] = useState("");
const [filterByProvider, setFilterByProvider] = useState("");
const [filterByType, setFilterByType] = useState(null);
const [filterByScheduled, setFilterByScheduled] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:-1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of ServiceOrders
	retrieveServiceOrdersAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Ordens de serviço válidas </h5>
			{/*<span style={{float:"right"}}>
			<Button 
				onClick={() => { setAlertTitle("Take it Easy!");setAlertBody("Recurso ainda não implementado! Aguarde!");setAlert(true)}}
			 >
			  Novo Serviço
			</Button>			
			</span>	*/}			
	</div>
);	

// Column Name Body Template  
const serviceBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Serviço</span>
			{rowData.ServiceOrderService.name}<br />
			<small style={{color:"silver"}}>Valor: R$ {rowData.ServiceTotalPrice+",00"}</small>
		</React.Fragment>
	);
}  

// Column customerName Body Template  
const customerNameBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Cliente</span>
			{rowData.CustomerUser.CustomerProfile.completeName}
		</React.Fragment>
	);
}  

// Column PRovider Body Template  
const providerNameBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Prestador</span>
			{rowData.ProviderUser.ProviderProfile.ProviderProfileCompany.realName}<br />
			<small style={{color:"silver"}}>{rowData.ProviderUser.ProviderProfile.ProviderProfilePerson.completeName}</small>
		</React.Fragment>
	);
}  

// Column Scheduled Date Body Template  
const scheduledBodyTemplate = (rowData) => {
	
	var periodArray = [];
	if (rowData.type == 1 || rowData.type == 2) {
		periodArray[1] = "Manhã 09:00 às 13:00";
		periodArray[2] = "Tarde 13:00 às 18:00";
		periodArray[3] = "Noite 18:00 às 21:00";
	} // end if
	else {
		periodArray[1] = "Em até 2h";
		periodArray[2] = "Em até 2h";
		periodArray[3] = "Em até 2h";
	} // end else
	
	return (
		<React.Fragment>
			<span className="p-column-title">Agendado para</span>
			{moment(rowData.scheduledDate).format("DD/MM/YYYY")}<br />
			<small style={{color:"silver"}}>{periodArray[rowData.scheduledPeriod]}</small>
		</React.Fragment>
	);
}  

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}<br />
			<small style={{color:"silver"}}>{moment(rowData.createdAt).format("HH:mm")}</small>
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {

	if (rowData.status == 0) {
		var activationStatus = "AGENDADO";
		var activationStatusLabel = "Agendado";
	} // end if	
	else if (rowData.status == 1) {
		var activationStatus = "REVIEW";
		var activationStatusLabel = "Em trânsito";
	} // end if
	else if (rowData.status == 3) {
		var activationStatus = "MESMODIA";
		var activationStatusLabel = "Em execução";
	} // end if	
	else if (rowData.status == 4) {
		var activationStatus = "ATIVADO";
		var activationStatusLabel = "Finalizado";
	} // end if	
	else if (rowData.status == 5) {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Em análise";
	} // end if	
	else if (rowData.status == 6) {
		var activationStatus = "ENCERRADO";
		var activationStatusLabel = "Em disputa";
	} // end if		
	else {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "N/A";
	} // end else
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span>
	</>
	)
}	

// Column Type Status Body Template  
const typeBodyTemplate = (rowData) => {
	
	if (rowData.type == 1) {
		var activationStatus = "AGENDADO";
		var activationStatusLabel = "Agendado";
	} // end if
	else if (rowData.type == 2) {
		var activationStatus = "MESMODIA";
		var activationStatusLabel = "Mesmo dia";
	} // end if
	else {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Urgente";
	} // end else
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span>
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Detalhes" icon="pi pi-search" className="p-button-outlined p-button-primary p-button-sm" style={{margin:"3px"}} onClick={() => { history.push("/admin/service/"+rowData.id+"") }}  />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByService && !filterByCustomer && !filterByProvider && !filterByScheduled && !filterByType && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if

	var filters = {};
	filters.serviceName = filterByService ? filterByService:null;
	filters.customerName = filterByCustomer ? filterByCustomer:null;
	filters.providerName = filterByProvider ? filterByProvider:null;
	filters.scheduledAt = filterByScheduled ? filterByScheduled:null;
	filters.type = filterByType ? filterByType.code:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {

	setFilterByService("");
	setFilterByCustomer("");
	setFilterByProvider("");
	setFilterByType(null);
	setFilterByScheduled("");
	setFilterByCreatedAt("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons
	
	// Service Name Filter
	const serviceNameFilter = <InputText style={{width:"100%"}} type="search" value={filterByService} onChange={(e) => setFilterByService(e.target.value)} className="p-column-filter" placeholder="Por serviço" />;	

	// Customer Filter
	const customerFilter = <InputText type="search" style={{width:"100%"}} value={filterByCustomer} onChange={(e) => setFilterByCustomer(e.target.value)} className="p-column-filter" placeholder="Por cliente" />;	

	// Provider Filter
	const providerFilter = <InputText type="search" style={{width:"100%"}} value={filterByProvider} onChange={(e) => setFilterByProvider(e.target.value)} className="p-column-filter" placeholder="Por prestador" />;	
	
	// ScheduledAt Filter
	const scheduledFilter = <Calendar locale="pt-BR" showButtonBar value={filterByScheduled} onChange={(e) => setFilterByScheduled(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Por data"/>;	
		
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Por data"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"AGENDADO","label":"Agendado","code":0},
	{"id":"REVIEW","label":"Em Trânsito","code":1},
	{"id":"MESMODIA","label":"Em execução","code":3},
	{"id":"ATIVADO","label":"Finalizado","code":4},
	{"id":"BLOQUEADO","label":"Em análise","code":5},
	{"id":"ENCERRADO","label":"Em disputa","code":6}];
	
	// Activation Status Filter
	const statusFilter = <Dropdown style={{width:"100%"}} value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Por status" className="p-column-filter" showClear />;
	
	// Set Status Dropdown Content
	const types = [{"id":"AGENDADO","label":"Agendado","code":1},{"id":"MESMODIA","label":"Mesmo dia","code":2},{"id":"BLOQUEADO","label":"Urgente","code":3}];

	// Activation Status Filter
	const typeFilter = <Dropdown style={{width:"100%"}} value={filterByType} options={types} onChange={(e) => setFilterByType(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Por tipo" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Filtrar" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		  

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveServiceOrdersAction = () => {

	var data = {
		status:[0,1,2,3,4,5],
		...lazyParams
	}
	var propsParam = {
		setServiceOrdersRows:setServiceOrdersRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	retrieveServiceOrders(data,propsParam);

} // end 

// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Ordens de Serviço válidas</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
							
						{/*<Toolbar style={{marginBottom:"4px"}} left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>*/}
							
						<DataTable ref={dt} value={serviceOrdersRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhuma ordem de serviço encontrada." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
							
							<Column field="ServiceOrderService.name" header="Serviço" body={serviceBodyTemplate} filter filterElement={serviceNameFilter} />
							
							<Column field="CustomerUser.CustomeProfile.completeName" header="Cliente" body={customerNameBodyTemplate} filter filterElement={customerFilter} />

							<Column field="ProviderUser.ProviderProfile.ProviderProfileCompany.realName" header="Prestador" body={providerNameBodyTemplate} filter filterElement={providerFilter} />
							
							<Column field="createdAt" header="Criado em" body={createdBodyTemplate} sortable filter filterElement={dateFilter} filterFunction={filterDate} />							
							
							<Column header="Tipo" body={typeBodyTemplate} filter filterElement={typeFilter} />							
							
							<Column field="scheduledDate" header="Agendado para" body={scheduledBodyTemplate} sortable filter filterElement={scheduledFilter} filterFunction={filterDate} />		

							{/*<Column field="ServiceTotalPrice" header="Valor" body={priceBodyTemplate} />*/}

							<Column header="Status" body={statusBodyTemplate} filter filterElement={statusFilter} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
							
						</DataTable>						
						
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<Dialog header={alertTitle} visible={alert} style={{ width: '30vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog>			
	</>	
	);
	
});


// Tab2Content Object
export const Tab2Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {


// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveServiceOrders
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [serviceOrdersRows, setServiceOrdersRows] = useState([]);

// Filter fields
const [filterByService, setFilterByService] = useState("");
const [filterByCustomer, setFilterByCustomer] = useState("");
const [filterByProvider, setFilterByProvider] = useState("");
const [filterByType, setFilterByType] = useState(null);
const [filterByCancelled, setFilterByCancelled] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:-1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of ServiceOrders
	retrieveServiceOrdersAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Ordens de serviço canceladas </h5>
			{/*<span style={{float:"right"}}>
			<Button 
				onClick={() => { setAlertTitle("Take it Easy!");setAlertBody("Recurso ainda não implementado! Aguarde!");setAlert(true)}}
			 >
			  Novo Serviço
			</Button>			
			</span>	*/}			
	</div>
);	

// Column Name Body Template  
const serviceBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Serviço</span>
			{rowData.ServiceOrderService.name}<br />
			<small style={{color:"silver"}}>Valor: R$ {rowData.ServiceTotalPrice+",00"}</small>
		</React.Fragment>
	);
}  

// Column customerName Body Template  
const customerNameBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Cliente</span>
			{rowData.CustomerUser.CustomerProfile.completeName}
		</React.Fragment>
	);
}  

// Column PRovider Body Template  
const providerNameBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Prestador</span>
			{rowData.ProviderUser.ProviderProfile.ProviderProfileCompany.realName}<br />
			<small style={{color:"silver"}}>{rowData.ProviderUser.ProviderProfile.ProviderProfilePerson.completeName}</small>
		</React.Fragment>
	);
}  

// Column cancelledBodyTemplate 
const cancelledBodyTemplate  = (rowData) => {

	return (
		<React.Fragment>
			<span className="p-column-title">Cancelado em</span>
			{moment(rowData.cancelledAt).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}  

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}<br />
			<small style={{color:"silver"}}>{moment(rowData.createdAt).format("HH:mm")}</small>
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {

	if (rowData.status == 99) {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Cancelada";
	} // end if			
	else {
		var activationStatus = "AGENDADO";
		var activationStatusLabel = "N/A";
	} // end else
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span><br />
			<small style={{color:"silver"}}>Cancelado pelo {rowData.cancelledBy == "provider" ? "Prestador" : "Cliente"}</small><br />
			<small style={{color:"silver"}}>{rowData.cancelReason}</small>	
	</>
	)
}	

// Column Type Status Body Template  
const typeBodyTemplate = (rowData) => {
	
	if (rowData.type == 1) {
		var activationStatus = "AGENDADO";
		var activationStatusLabel = "Agendado";
	} // end if
	else if (rowData.type == 2) {
		var activationStatus = "MESMODIA";
		var activationStatusLabel = "Mesmo dia";
	} // end if
	else {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Urgente";
	} // end else
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span>
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Detalhes" icon="pi pi-search" className="p-button-outlined p-button-primary p-button-sm" style={{margin:"3px"}} onClick={() => { history.push("/admin/service/"+rowData.id+"") }}  />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByService && !filterByCustomer && !filterByProvider && !filterByCancelled && !filterByType && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if

	var filters = {};
	filters.serviceName = filterByService ? filterByService:null;
	filters.customerName = filterByCustomer ? filterByCustomer:null;
	filters.providerName = filterByProvider ? filterByProvider:null;
	filters.cancelledAt = filterByCancelled ? filterByCancelled:null;
	filters.type = filterByType ? filterByType.code:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {

	setFilterByService("");
	setFilterByCustomer("");
	setFilterByProvider("");
	setFilterByType(null);
	setFilterByCancelled("");
	setFilterByCreatedAt("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons
	
	// Service Name Filter
	const serviceNameFilter = <InputText style={{width:"100%"}} type="search" value={filterByService} onChange={(e) => setFilterByService(e.target.value)} className="p-column-filter" placeholder="Por serviço" />;	

	// Customer Filter
	const customerFilter = <InputText type="search" style={{width:"100%"}} value={filterByCustomer} onChange={(e) => setFilterByCustomer(e.target.value)} className="p-column-filter" placeholder="Por cliente" />;	

	// Provider Filter
	const providerFilter = <InputText type="search" style={{width:"100%"}} value={filterByProvider} onChange={(e) => setFilterByProvider(e.target.value)} className="p-column-filter" placeholder="Por prestador" />;	
	
	// cancelledAt Filter
	const cancelledFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCancelled} onChange={(e) => setFilterByCancelled(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Por data"/>;	
		
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Por data"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"AGENDADO","label":"Agendado","code":0},
	{"id":"REVIEW","label":"Em Trânsito","code":1},
	{"id":"MESMODIA","label":"Em execução","code":3},
	{"id":"ATIVADO","label":"Finalizado","code":4},
	{"id":"BLOQUEADO","label":"Em análise","code":5},
	{"id":"ENCERRADO","label":"Em disputa","code":6}];
	
	// Activation Status Filter
	const statusFilter = <Dropdown style={{width:"100%"}} value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Por status" className="p-column-filter" showClear />;
	
	// Set Status Dropdown Content
	const types = [{"id":"AGENDADO","label":"Agendado","code":1},{"id":"MESMODIA","label":"Mesmo dia","code":2},{"id":"BLOQUEADO","label":"Urgente","code":3}];

	// Activation Status Filter
	const typeFilter = <Dropdown style={{width:"100%"}} value={filterByType} options={types} onChange={(e) => setFilterByType(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Por tipo" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Filtrar" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		  

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveServiceOrdersAction = () => {

	var data = {
		status:[99],
		...lazyParams
	}
	var propsParam = {
		setServiceOrdersRows:setServiceOrdersRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	retrieveServiceOrders(data,propsParam);

} // end 

// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Ordens de Serviço válidas</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
							
						{/*<Toolbar style={{marginBottom:"4px"}} left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>*/}
							
						<DataTable ref={dt} value={serviceOrdersRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhuma ordem de serviço cancelada encontrada." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
							
							<Column field="ServiceOrderService.name" header="Serviço" body={serviceBodyTemplate} filter filterElement={serviceNameFilter} />
							
							<Column field="CustomerUser.CustomeProfile.completeName" header="Cliente" body={customerNameBodyTemplate} filter filterElement={customerFilter} />

							<Column field="ProviderUser.ProviderProfile.ProviderProfileCompany.realName" header="Prestador" body={providerNameBodyTemplate} filter filterElement={providerFilter} />
							
							<Column field="createdAt" header="Criado em" body={createdBodyTemplate} sortable filter filterElement={dateFilter} filterFunction={filterDate} />							
							
							<Column header="Tipo" body={typeBodyTemplate} filter filterElement={typeFilter} />							
							
							<Column field="cancelledAt" header="Cancelado em" body={cancelledBodyTemplate} sortable filter filterElement={cancelledFilter} filterFunction={filterDate} />		

							{/*<Column field="ServiceTotalPrice" header="Valor" body={priceBodyTemplate} />*/}

							<Column header="Status" body={statusBodyTemplate} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
							
						</DataTable>						
						
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<Dialog header={alertTitle} visible={alert} style={{ width: '30vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog>			
	</>	
	);

});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ServiceOrders);	

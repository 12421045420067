import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import * as config from '../../Config/api.js';

import { DataGrid } from '@material-ui/data-grid';
import moment from "moment";
import 'moment/locale/pt-br';

import {Link,useParams, useHistory} from "react-router-dom";

import ReactStars from 'react-stars'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import NotIcon from '@material-ui/icons/Block';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import RoomIcon from '@material-ui/icons/Room';

import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import visa from '../../assets/Images/visa.svg'; // Tell Webpack this JS file uses this image
import mastercard from '../../assets/Images/mastercard.svg'; // Tell Webpack this JS file uses this image
import amex from '../../assets/Images/amex.svg'; // Tell Webpack this JS file uses this image
import diners from '../../assets/Images/diners.svg'; // Tell Webpack this JS file uses this image
import elo from '../../assets/Images/elo.svg'; // Tell Webpack this JS file uses this image
import genericFlag from '../../assets/Images/genericFlag.svg'; // Tell Webpack this JS file uses this image

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ServiceActions from 'Store/Actions/Service'


const useStyles = makeStyles(styles);

const ServiceOrderView = props => {

// set vars from props
const { 
	appLoading,
	setDialog,
	dialog,
	providerApproval,
	retrieveServiceOrder,
	setAppLoading	
} = props;		

const { id } = useParams();

const [serviceOrder, setServiceOrderCol] = React.useState({});
const [mapImageUrl, setMapImageUrl] = React.useState("");  
const [avatar, setAvatar] = React.useState(null);  
const [contentVisible, setContentVisible] = React.useState(false);  

const [dialogAlert, setAlertDialog] = React.useState(false); // state for tab
const [dialogMessage, setDialogMessage] = React.useState(""); // state for tab
const [dialogTitle, setDialogTitle] = React.useState("Verifique as informações"); // state for tab
const [dialogAction, setDialogAction] = React.useState(false); // state for tab
  
const [agendaGrid, setAgenda] = React.useState([]); // set agenda obj

const [value, setValue] = React.useState(0);
const [statusHistory, setStatusHistory] = React.useState([]);
const [paymentHistory, setPaymentHistory] = React.useState([]);
const [penaltyTaxes, setPenaltyTaxes] = React.useState([]);

const classes = useStyles();


console.log("[serviceOrder]--->"+JSON.stringify(serviceOrder,null,2));

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const handleChange = (event, newValue) => {
setValue(newValue);
};
 
const calculateAge = (birthday) => {

	var a = moment();
	var b = moment(birthday, 'YYYY');  
	var diff = a.diff(b, 'years'); // calculates patient's age in years
	return diff; // this prints out the age

} 

const maskDocument = (document) => {
	
	// Clean and format
	var cleaned = ('' + document).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/)
	  if (match) {
		return '' + match[1] + '.' + match[2] + '.' + match[3]+ '-' + match[4];
	  } // end if
	  else {
		return "CPF Inválido";
	  } // end else	  
	
}

const maskCompanyDocument = (document) => {
	
	// Clean and format
	var cleaned = ('' + document).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/)
	  if (match) {
		return '' + match[1] + '.' + match[2] + '.' + match[3] + '/' + match[4] + '-' + match[5];
	  } // end if
	  else {
		return "CNPJ Inválido";
	  } // end else	  
	
}

const maskPhone = (phone) => {
	
	// Clean and format
	var cleaned = ('' + phone).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/)
	  if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	  } // end if
	  else {
		return "Telefone Inválido";
	  } // end else	  
	
}

const maskCep = (cep) => {
	
	// Clean and format
	var cleaned = ('' + cep).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{5})(\d{3})$/)
	  if (match) {
		return match[1] + '-' + match[2];
	  } // end if
	  else {
		return "CEP Inválido";
	  } // end else	  
	
}

const maskGender = (gender) => {
	
	if (gender === 1) {
		return "Masculino";
	} // end if
	else if (gender === 2) {
		return "Feminino";
	} // end else if
	else {
		return "Indefinido";
	} // end else
	
}

// set Use of history for PUSH 
let history = useHistory();
  
// When mount component
React.useEffect(() => {

	setAppLoading(true);

	var propsParam = {
		setServiceOrderCol:setServiceOrderCol,
		setMapImageUrl:setMapImageUrl,
		setAvatar:setAvatar,
		setContentVisible:setContentVisible,
		setStatusHistory:setStatusHistory,
		setPaymentHistory:setPaymentHistory,
		setPenaltyTaxes:setPenaltyTaxes
	}
	retrieveServiceOrder(id,propsParam);
			
}, []);  

    /*const events1 = [
        { status: 'Pagamento Autorizado', date: '05/10/2021 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0' },
		{ status: 'Cobrança de Taxa Alteração', date: '05/10/2021 13:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
        { status: 'Pagamento Cancelado', date: '06/10/2021 14:00', icon: 'pi pi-cog', color: '#673AB7' },
        { status: 'Cobrança de Taxa Cancelamento', date: '06/10/2021 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' }
    ];
	
   const events2 = [
        'Agendado', 'Em trânsito', 'Em execução', 'Finalizado'
    ];	
	
	
    const events3 = [
	    { status: 'Agendado', date: '05/10/2021 10:30', icon: 'pi pi-check', color: '#333' },
		{ status: 'Em trânsito', date: '05/10/2021 10:30', icon: 'pi pi-check', color: '#333' },
		{ status: 'Em execução', date: '05/10/2021 10:30', icon: 'pi pi-check', color: 'green' },
    ];	*/
	
    const customizedMarker = (item) => {
        return (
            <span className="custom-marker p-shadow-2" style={{ backgroundColor:serviceOrder.status === item.status ? (item.status == 5 ? "#ffcdd2" : (item.status == 4 ? "#c8e6c9" : "#333") ) : "#333",color:item.status == 5 ? "#c63737" : item.status == 4 ? "#256029" : "#FFF" }}>
                <i className="pi pi-check"></i>
            </span>
        );
    };	
	
    const paymentTimelineTemplate = (item) => {
        return (
            <span>
                {item.memo}<br />
				<small style={{color:"gray"}}>{item.statusLabel}</small>
            </span>
        );
    };	

if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
return;
  }

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

if(contentVisible) {	 
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Gestão de Serviços</Typography>
	  <Typography color="textSecondary"><Link to="/admin/serviceorders">Ordens de Serviço</Link></Typography>
	  <Typography color="textPrimary">Visualizando Ordem de serviço</Typography>
	</Breadcrumbs>
	
	<Divider />

	{serviceOrder.status != "9" &&
	<span style={{float:"right",marginRight:"30px",marginTop:"30px"}}>
		<Button variant="contained" 
			onClick={() => alert("Em desenvolvimento...") }
		 >
		  Ações disponíveis
		</Button>
	</span>	
	}
	
	<h3 style={{marginTop:"30px"}}>Visualizando Ordem de serviço #<strong>{serviceOrder.id}</strong></h3>	
	<h5>Navegue nas informações do prestador abaixo.</h5>
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>

		  <Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
			variant="scrollable"
			scrollButtons="auto"
			centered
		  >
			<Tab label="Dados da Ordem de Serviço" />
			<Tab label="Histórico de Pagamento" />
			<Tab label="Comissões / Split" />
			<Tab disabled={serviceOrder.status >= 4 ? false : true} label="Problemas Relatados" />
			<Tab disabled={serviceOrder.status >= 4  ? false : true} label="Reclamações ou denúncias" />
		</Tabs>

     	  <Divider />
			<TabPanel value={value} index={0}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Dados da Ordem de Serviço</h4>
					  </CardHeader>
					  <CardBody>	 

						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Histórico de Status</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">
									  Status atual <br />
									  <small style={{color:"silver"}}> Status que a ordem de serviço se encontra</small>
								  </TableCell>
								  <TableCell align="left">

								  {serviceOrder.status == 0 &&
								  <>
									<span class="product-badge status-agendado">Agendado</span> <small><i> Ordem de serviço está agendada aguardando a execução o serviço.</i></small>
								  </>
								  }		

								  {serviceOrder.status == 1 &&
								  <>
									<span class="product-badge status-review">Em trânsito</span> <small><i>Prestador está em trânsito para o local do serviço.</i></small>
								  </>
								  }		
								  
								  {serviceOrder.status == 3 &&
								  <>
									<span class="product-badge status-mesmodia">Em execução</span> <small><i>O prestador etá no local e está executando o serviço.</i></small>
		
								  </>
								  }
							  
								  {serviceOrder.status == 4 &&
								  <>
									<span class="product-badge status-ativado">Finalizado</span> <small><i> O prestador finalizou o serviço com sucesso.</i></small>
								  </>
								  }
								  
								  {serviceOrder.status == 5 &&
								  <>
									<span class="product-badge status-bloqueado">Em análise</span> <small><i> O prestador finalizou o serviço mas teve problemas.</i></small>
								  </>
								  }				

								  {serviceOrder.status == 6 &&
								  <>
									<span class="product-badge status-encerrado">Em disputa</span> <small><i> A ordem de serviço está em disputa devido a uma problema.</i></small>
								  </>
								  }	

								  {serviceOrder.status == 99 &&
								  <>
									<span class="product-badge status-bloqueado">Cancelada</span> <small><i> A ordem de serviço encontra-se cancelada. </i></small>
								  </>
								  }										  
								  
								  </TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">
									  Histórico do Status
								  </TableCell>
								  <TableCell align="left">
								  
								   {statusHistory.length > 0 &&
									<Timeline value={statusHistory} marker={customizedMarker} layout="horizontal" align="top" content={(item) => { return (<><span>{item.statusLabel}</span><br /><small className="p-text-secondary">{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</small></>)} } />
								   }
								   
								   {statusHistory.length == 0 &&
									<span>Não há histórico de status para esse pedido.</span>
								   }
									
								  </TableCell>
								</TableRow>
								
							
							</TableBody>
						  </Table>
						</TableContainer>
						

						{/* STATUS 1 */}
						{serviceOrder.status === 1 &&
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Profissional em trânsito para o local</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>
										
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Acompanhe em tempo real</TableCell>
								  <TableCell align="left"><Button>Abrir localização</Button></TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Hora estimada de chegada</TableCell>
								  <TableCell align="left">{moment(serviceOrder.willArriveAt).locale('pt-br').format("HH:mm")}</TableCell>
								</TableRow>
		
							</TableBody>
						  </Table>
						</TableContainer>
						}
						

						{/* STATUS 3 */}
						{serviceOrder.status === 3 &&
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Serviço em andamento</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Iniciado às</TableCell>
								  <TableCell align="left">{moment(serviceOrder.startedAt).locale('pt-br').format("HH:mm")}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Tempo decorrido</TableCell>
								  <TableCell align="left">{Math.floor(moment.duration(moment().diff(moment(serviceOrder.startedAt))).asMinutes())} minutos</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Hora estimada de finalização</TableCell>
								  <TableCell align="left">{moment(serviceOrder.startedAt).add(serviceOrder.ServiceOrderService.Service.effort,"minutes").locale('pt-br').format("HH:mm")}</TableCell>
								</TableRow>								
		
							</TableBody>
						  </Table>
						</TableContainer>	
						}						
								
								
						{/* STATUS 4 */}
						{serviceOrder.status === 4 &&
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Serviço finalizado <strong style={{color:"green"}}>com sucesso</strong></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Iniciado em</TableCell>
								  <TableCell align="left">{moment(serviceOrder.startedAt).locale('pt-br').format("DD/MM/YYYY HH:mm")}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Finalizado em</TableCell>
								  <TableCell align="left">{moment(serviceOrder.finishedAt).locale('pt-br').format("DD/MM/YYYY HH:mm")}</TableCell>
								</TableRow>
												
								<TableRow >
								  <TableCell component="th" scope="row">Tempo de duração</TableCell>
								  <TableCell align="left">{Math.floor(moment.duration(moment(serviceOrder.finishedAt).diff(moment(serviceOrder.startedAt))).asMinutes())} minutos  ({Math.floor(moment.duration(moment(serviceOrder.finishedAt).diff(moment(serviceOrder.startedAt))).asHours())} horas)</TableCell>
								</TableRow>
		
							</TableBody>
						  </Table>
						</TableContainer>
						}

						{/* STATUS 5 */}
						{serviceOrder.status === 5 &&
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Serviço finalizado <strong style={{color:"orange"}}>com problema</strong></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Iniciado em</TableCell>
								  <TableCell align="left">{moment(serviceOrder.startedAt).locale('pt-br').format("DD/MM/YYYY HH:mm")}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Finalizado em</TableCell>
								  <TableCell align="left">{moment(serviceOrder.finishedAt).locale('pt-br').format("DD/MM/YYYY HH:mm")}</TableCell>
								</TableRow>
												
								<TableRow >
								  <TableCell component="th" scope="row">Tempo de duração</TableCell>
								  <TableCell align="left">{Math.floor(moment.duration(moment(serviceOrder.finishedAt).diff(moment(serviceOrder.startedAt))).asMinutes())} minutos  ({Math.floor(moment.duration(moment(serviceOrder.finishedAt).diff(moment(serviceOrder.startedAt))).asHours())} horas)</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Problema relatado</TableCell>
								  <TableCell align="left">{serviceOrder.issueReason}</TableCell>
								</TableRow>								
		
							</TableBody>
						  </Table>
						</TableContainer>
						}						
										
						{/* STATUS 6 */}
						{serviceOrder.status === 6 &&
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Serviço finalizado <strong style={{color:"red"}}>mas em disputa</strong></TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Iniciado em</TableCell>
								  <TableCell align="left">{moment(serviceOrder.startedAt).locale('pt-br').format("DD/MM/YYYY HH:mm")}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Finalizado em</TableCell>
								  <TableCell align="left">{moment(serviceOrder.finishedAt).locale('pt-br').format("DD/MM/YYYY HH:mm")}</TableCell>
								</TableRow>
												
								<TableRow >
								  <TableCell component="th" scope="row">Tempo de duração</TableCell>
								  <TableCell align="left">{Math.floor(moment.duration(moment(serviceOrder.finishedAt).diff(moment(serviceOrder.startedAt))).asMinutes())} minutos  ({Math.floor(moment.duration(moment(serviceOrder.finishedAt).diff(moment(serviceOrder.startedAt))).asHours())} horas)</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Motivo da disputa [!]</TableCell>
								  <TableCell align="left">{serviceOrder.issueReason}</TableCell>
								</TableRow>		
								
								<TableRow >
								  <TableCell component="th" scope="row">Sobre a disputa [!]</TableCell>
								  <TableCell align="left">Pagamento do serviço não foi realizado pois o mesmo está em disputa. Necessário analisar problema antes de liberar o pagamento.</TableCell>
								</TableRow>								
		
							</TableBody>
						  </Table>
						</TableContainer>
						}						

						{/* STATUS 99 */}
						{serviceOrder.status === 99 &&
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Serviço cancelado</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Cancelado em</TableCell>
								    <TableCell align="left">{moment(serviceOrder.cancelledAt).locale('pt-br').format("DD/MM/YYYY HH:mm")}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Cancelado pelo</TableCell>
								  <TableCell align="left">{serviceOrder.cancelledBy == "provider" ? "Prestador" : serviceOrder.cancelledBy == "customer" ? "Cliente" : "Gestor"}</TableCell>
								</TableRow>
												
								<TableRow >
								  <TableCell component="th" scope="row">Motivo do cancelamento [!]</TableCell>
								  <TableCell align="left">{serviceOrder.cancelReason}</TableCell>
								</TableRow>		
		
							</TableBody>
						  </Table>
						</TableContainer>
						}						
						
							  
				 		<Box style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}}>
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do Cliente</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome Completo</TableCell>
								  <TableCell align="left">{serviceOrder.CustomerUser.CustomerProfile.completeName}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Como gosta de ser chamado</TableCell>
								  <TableCell align="left">{serviceOrder.CustomerUser.CustomerProfile.alias}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell component="th" scope="row">Email</TableCell>
								  <TableCell align="left">{serviceOrder.CustomerUser.email}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Telefone Celular</TableCell>
								  <TableCell align="left">{maskPhone(serviceOrder.CustomerUser.mobilePhone)}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Gênero</TableCell>
								  <TableCell align="left">{maskGender(serviceOrder.CustomerUser.CustomerProfile.gender)}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Data de Nascimento</TableCell>
								  <TableCell align="left">{serviceOrder.CustomerUser.CustomerProfile.birthday ? moment(serviceOrder.CustomerUser.CustomerProfile.birthday).format("DD/MM/YYYY")+ "- ("+calculateAge(serviceOrder.CustomerUser.CustomerProfile.birthday)+" anos)" : "Não informada"}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell component="th" scope="row">Qualificação do cliente</TableCell>
								  <TableCell align="left">	
										{serviceOrder.CustomerUser.CustomerRating &&
											<ReactStars
											  count={5}
											  edit={false}
											  value={serviceOrder.CustomerUser.CustomerRating.generalRating}
											  half={true}
											  size={36}
											  color2={'#ffd700'} />	
										}
										
										{!serviceOrder.CustomerUser.CustomerRating &&
												<div>Cliente ainda não foi qualificado</div>
										}	
									</TableCell>
								</TableRow>									

							</TableBody>
						  </Table>
						</TableContainer>
						

						<TableContainer component={Box} mt={2} ml={1}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do Prestador</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Razão Social da empresa</TableCell>
								  <TableCell align="left">{serviceOrder.ProviderUser.ProviderProfile.ProviderProfileCompany.realName}</TableCell>
								</TableRow>

								{/*<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Avatar</TableCell>
								  <TableCell align="left">
									<img src={avatar} style={{height:"150px",width:"auto",backgroundColor:"gray"}} />
								 </TableCell>
								</TableRow>*/}

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Nome Completo</TableCell>
								  <TableCell align="left">{serviceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.completeName}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Email</TableCell>
								  <TableCell align="left">{serviceOrder.ProviderUser.email}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Telefone Celular</TableCell>
								  <TableCell align="left">{maskPhone(serviceOrder.ProviderUser.mobilePhone)}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Data de Nascimento</TableCell>
								  <TableCell align="left">{moment(serviceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.birthday).format("DD/MM/YYYY")} - ({calculateAge(serviceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.birthday)} anos)</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">CPF</TableCell>
								  <TableCell align="left">{maskDocument(serviceOrder.ProviderUser.ProviderProfile.ProviderProfilePerson.personalDocument)}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Qualificação do prestador</TableCell>
								  <TableCell align="left">	
										{serviceOrder.ProviderUser.ProviderRating &&
											<ReactStars
											  count={5}
											  edit={false}
											  value={serviceOrder.ProviderUser.ProviderRating.generalRating}
											  half={true}
											  size={36}
											  color2={'#ffd700'} />	
										}
										
										{!serviceOrder.ProviderUser.ProviderRating &&
												<div>Prestador ainda não foi qualificado</div>
										}	
									</TableCell>
								</TableRow>									

							</TableBody>
						  </Table>
						</TableContainer>
						</Box>	


						{(serviceOrder.ServiceOrderCustomerRating || serviceOrder.ServiceOrderProviderRating) && 
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Qualificações da ordem de serviço</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

							{serviceOrder.ServiceOrderCustomerRating && 
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Como cliente qualificou prestador</TableCell>
								  <TableCell align="left">
								  
										<div>
										
										{serviceOrder.ServiceOrderCustomerRating &&
											<ReactStars
											  count={5}
											  edit={false}
											  value={serviceOrder.ServiceOrderCustomerRating.generalRating}
											  half={true}
											  size={36}
											  color2={'#ffd700'} />	
										}
										
										{!serviceOrder.ServiceOrderCustomerRating &&
												<div>Cliente não qualificou prestador nesse serviço</div>
										}											
										
										</div>								  
											
								  </TableCell>
								</TableRow>
							}								

							{serviceOrder.ServiceOrderProviderRating && 
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Como prestador qualificou cliente</TableCell>
								  <TableCell align="left">
								  
										<div>
										
										{serviceOrder.ServiceOrderProviderRating &&
											<ReactStars
											  count={5}
											  edit={false}
											  value={serviceOrder.ServiceOrderProviderRating.generalRating}
											  half={true}
											  size={36}
											  color2={'#ffd700'} />	
										}
										
										{!serviceOrder.ServiceOrderProviderRating &&
												<div>Prestador não qualificou cliente nesse serviço</div>
										}										
										
										</div>								  
											
								  </TableCell>
								</TableRow>	
							}								

							</TableBody>
						  </Table>
						</TableContainer>
						}								
		
		
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Dados do serviço</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>
												

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Categoria</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.ServiceCategories[0].breadcrumb}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Nome do serviço</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.name}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Descrição</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.description}</TableCell>
								</TableRow>								

								<TableRow >
								  <TableCell component="th" scope="row">Garantia do serviço</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.warrantyTerm}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell component="th" scope="row">Tempo médio do serviço</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderService.Service.effort} {serviceOrder.ServiceOrderService.Service.effortUnity}</TableCell>
								</TableRow>								

							</TableBody>
						  </Table>
						</TableContainer>
						
						
						{/* Detalhes agendamento */}
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Detalhes do agendamento</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Data agendada</TableCell>
								  <TableCell align="left">{moment(serviceOrder.scheduledDate).locale('pt-br').format("DD/MM/YYYY (dddd)")}</TableCell>
								</TableRow>
								
								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Tipo de agendamento</TableCell>
								  <TableCell align="left">
								  
									  { serviceOrder.type === 1 &&
										<span className="product-badge status-agendado">Agendado</span>
									  }
									  
									  { serviceOrder.type === 2 &&
										<span className="product-badge status-mesmodia">Mesmo dia</span>
									  }

									  { serviceOrder.type === 3 &&
										<span className="product-badge status-bloqueado">Urgente</span>
									  }								  
								  
								  </TableCell>
								</TableRow>								
								
								<TableRow >
								  <TableCell component="th" scope="row">Horário / Período</TableCell>
								  <TableCell align="left">
							    
									  {(serviceOrder.type === 1 || serviceOrder.type === 2) &&
										<>
										{ serviceOrder.scheduledPeriod === 1 &&
											<span>Manhã 09:00 às 13:00</span>
										}

										{ serviceOrder.scheduledPeriod === 2 &&
											<span>Tarde 13:00 às 18:00</span>
										}

										{ serviceOrder.scheduledPeriod === 3 &&
											<span>Noite 18:00 às 21:00</span>
										}										
										</>
									  }	

									  {serviceOrder.type === 3 &&
											<span>Em até 2h</span>
									  }										  
								  
								  </TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Forma de Pagamento</TableCell>
								  <TableCell align="left" style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
								  
								  {serviceOrder.paymentMethod === 1 &&
									<span>Cartão de Crédito</span>
								  }
								  
								  {serviceOrder.paymentMethod === 2 &&
									<span>PIX</span>
								  }								  
								  
								  {serviceOrder.paymentMethod === 1 &&
								  <div style={{marginLeft:"20px"}}>
										{serviceOrder.ServiceOrderCreditCard &&
	
												<Card style={{margin:"10px",maxWidth:"320px",padding:"10px"}} key={serviceOrder.ServiceOrderCreditCard.id}>

													<Box style={{display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"start"}}>
														
														<Box>										
															<img src={serviceOrder.ServiceOrderCreditCard.cardFlag == "visa" ? visa : serviceOrder.ServiceOrderCreditCard.cardFlag == "mastercard" ? mastercard : serviceOrder.ServiceOrderCreditCard.cardFlag == "amex" ? amex : serviceOrder.ServiceOrderCreditCard.cardFlag == "diners" ? diners : serviceOrder.ServiceOrderCreditCard.cardFlag == "elo" ? elo : serviceOrder.ServiceOrderCreditCard.cardFlag == "generic" ? genericFlag : null} alt="{serviceOrder.ServiceOrderCreditCard.cardFlag}" style={{marginLeft:"10px",marginRight:"10px",width:"75px"}} />
														</Box>
														
														<Box m={1}>	
																<div style={{textAlign:"left",fontSize:"0.85rem",lineHeight:"25px"}}>
																	{serviceOrder.ServiceOrderCreditCard.holderName}<br />
																<h4 style={{marginTop:"0px",marginBottom:"0px",fontWeight:"bold"}}>{serviceOrder.ServiceOrderCreditCard.referenceNumber}</h4>
																Expiração {serviceOrder.ServiceOrderCreditCard.expirationDate}
																</div>	
														</Box>	
													
													</Box>
					
												</Card>
										}										
								  </div>
								  }
								  
								  </TableCell>
								</TableRow>		

								<TableRow >
								  <TableCell component="th" scope="row">Informações adicionais</TableCell>
								  <TableCell align="left">
								  
								  
							<Box mt={2} mb={2} style={{textAlign:"center",alignItems:"stretch",justifyContent:"center",display:"flex",flexDirection:"column"}}>
									
									{serviceOrder.ServiceOrderMetaInformations.map( (item) => {
									
									if (item.fieldId == 2) {
										var itemValue = JSON.parse(item.fieldValue); 
									} // end if
									else {
										var itemValue = item.fieldValue;
									} // end else
									
									return (
										<Box ml={2} mr={2} mb={2} style={{alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} key={item.id}>
										
											<Box style={{flex:1}}>
											
												<Box style={{marginBottom:"10px",alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>
													<div> 
														<div style={{fontSize:"0.9rem",color:"#555555"}}>{item.fieldLabel}</div>
													</div>
												</Box>									
											
											</Box>
											
											<Box style={{flex:1}}>								
													<Box style={{fontSize:"0.9rem",border:"1px solid gray",backgroundColor:"#FFF",padding:"10px",borderRadius:"5px",textAlign:"left"}}>
														
														{Array.isArray(itemValue) &&
														<Box style={{marginTop:"0px", display: 'flex',flexWrap: 'wrap', justifyContent: 'flex-start',overflow: 'hidden',}}>													
														<GridList style={{display:"flex",padding:"0",flexWrap:"nowrap",listStyle:"none",overflowY:"auto"}} cols={3}>									
															{itemValue.map((item, index) => {	
														
																var extraImage = config.API_URL+"/service/extrainfoimage/"+serviceOrder.CustomerUserId+"/"+item.filename+"";
																
																return (
																	<img src={extraImage} style={{width:"200px",height:"auto"}} />
																)
																
																} 
																		
															)}	
														 </GridList>														
														</Box>	
														}
														
														{!Array.isArray(itemValue) &&
															<div>{itemValue}</div>
														}														
														
													</Box>								
											</Box>								
										
										</Box>
									)
									
									})
									
									}
									
					</Box>								  
								  
								  
								  </TableCell>
								</TableRow>		
		
							</TableBody>
						  </Table>
						</TableContainer>	



						{/* Detalhes da cobrança */}
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Detalhes da cobrança 
								{serviceOrder.status == 99 &&
									<span className="product-badge status-bloqueado" style={{marginLeft:"15px"}}>Cobrança cancelada</span>
								}
								</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Valor do Serviço</TableCell>
								  <TableCell align="left">{formatter.format(serviceOrder.ServiceBasisPrice)}</TableCell>
								</TableRow>
								
								{serviceOrder.type === 3 &&
									<TableRow >
									  <TableCell style={{width:"300px"}} component="th" scope="row">Taxa de Urgência</TableCell>
									  <TableCell align="left"><span className="product-badge status-bloqueado">{formatter.format(serviceOrder.ServiceUrgentFee)}</span></TableCell>
									</TableRow>	
								}								
			
								{serviceOrder.ServiceDiscount > 0 &&
									<TableRow >
									  <TableCell component="th" scope="row">Desconto (crédito, promoção etc)</TableCell>
									  <TableCell align="left">{formatter.format(serviceOrder.ServiceDiscount)}</TableCell>
									</TableRow>
								}

								<TableRow >
								  <TableCell component="th" scope="row" style={{fontWeight:"bold"}}>Valor Total</TableCell>
								  <TableCell align="left" style={{fontWeight:"bold"}}>{formatter.format(serviceOrder.ServiceTotalPrice)}</TableCell>
								</TableRow>		
		
							</TableBody>
						  </Table>
						</TableContainer>		
						
						

						{/* Detalhes da cobrança de taxa de penalidade */}
						{penaltyTaxes.length > 0 &&
						<>						
						
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Cobrança de taxa de penalidade por cancelamento ou alteração</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

										{penaltyTaxes.map( (item,index) => {

											var fieldLabel = item.history;
											
											if (item.valueType == "B" || item.valueType == "C" || item.valueType == "D") {
												var fieldValue = formatter.format(item.value);
											} // end if
											else {
												var fieldValue = item.value+"%";
											} // end else
												
										    var taxOrigin = item.InstanceType == "customer" ? "Cliente" : "Prestador";

											return (
											<>
											<TableRow >
											  <TableCell component="th" scope="row" colspan="2"><strong>Cobrança de taxa #{index+1}</strong></TableCell>
											</TableRow>		

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Origem</TableCell>
											  <TableCell align="left">{taxOrigin}</TableCell>
											</TableRow>	

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Descrição</TableCell>
											  <TableCell align="left">{item.memo}</TableCell>
											</TableRow>	

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Taxa cobrada</TableCell>
											  <TableCell align="left">{formatter.format(item.value)}</TableCell>
											</TableRow>							

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Data da cobrança</TableCell>
											  <TableCell align="left">{moment(item.createdAt).format("DD/MM/YYYY hh:mm")}</TableCell>
											</TableRow>
											
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Motivo da cobrança</TableCell>
											  <TableCell align="left">
											  
											  {item.penaltyType == 2 &&
											  <>
												<span className="product-badge status-encerrado">Alteração do pedido</span> <small> muito em cima da hora (faltando menos de 1h e 30min do horário de execução)</small>
											  </>
											  }
											  
											  {item.penaltyType == 1 &&
											  <>
												<span className="product-badge status-bloqueado">Cancelamento do pedido</span> <small>muito em cima da hora (faltando menos de 1h e 30min do horário de execução)</small>
											  </>
											  }											  
											  
											  </TableCell>
											</TableRow>		
											</>
											)}	

										 )}	

		
							</TableBody>
						  </Table>
						</TableContainer>
						</>
						}						


						{/* Order Billing */}
						{serviceOrder.ServiceOrderBilling &&								
								<TableContainer component={Box} mt={2}>
								  <Table style={{minWwidth:"500px"}} aria-label="simple table">

									<TableHead>
									  <TableRow style={{backgroundColor:"#EEE"}}>
										<TableCell colSpan={3}>{serviceOrder.ServiceOrderBilling.type = "PF" ? "Faturamento Pessoa Física" : "Faturamento Pessoa Jurídica"}</TableCell>
									  </TableRow>
									</TableHead>

									<TableBody>
													
									<TableRow >
									  <TableCell style={{width:"300px"}} component="th" scope="row">Nome Completo</TableCell>
									  <TableCell align="left">{serviceOrder.ServiceOrderBilling.billingName}</TableCell>
									</TableRow>

									<TableRow >
									  <TableCell style={{width:"300px"}} component="th" scope="row">{serviceOrder.ServiceOrderBilling.type = "PF" ? "CPF" : "CNPJ"}</TableCell>
									  <TableCell align="left">{serviceOrder.ServiceOrderBilling.billingDocument}</TableCell>
									</TableRow>
									
									<TableRow >
									  <TableCell component="th" scope="row">Endereço</TableCell>
									  <TableCell align="left">{serviceOrder.ServiceOrderBilling.fullAddress}</TableCell>
									</TableRow>


									</TableBody>
								  </Table>
								</TableContainer>
						}

						<Box style={{display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"center"}}>
						<TableContainer component={Box} mt={2} mr={1}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Endereço do serviço</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">CEP</TableCell>
								  <TableCell align="left">{maskCep(serviceOrder.ServiceOrderAddress.postalCode)}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Endereço Completo</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderAddress.fullAddress}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Bairro</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderAddress.suburb}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">Cidade</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderAddress.city}</TableCell>
								</TableRow>

								<TableRow >
								  <TableCell component="th" scope="row">UF</TableCell>
								  <TableCell align="left">{serviceOrder.ServiceOrderAddress.state}</TableCell>
								</TableRow>

							</TableBody>
						  </Table>
						</TableContainer>
		

						<TableContainer component={Box} mt={2}>
						  <Table aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Localização aproximada do endereço no Google Maps</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell component="th" scope="row"><img src={mapImageUrl} style={{minHeight:"250px",backgroundColor:"gray"}} alt="Google Maps Location" /></TableCell>
								</TableRow> 


							</TableBody>
						  </Table>
						</TableContainer>
						</Box>		
	
					  </CardBody>
					</Card>	 
 
			</TabPanel>
				  
			<TabPanel value={value} index={1}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <RoomIcon />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Histórico de pagamento</h4>
					  </CardHeader>
					  <CardBody>	  
				 				
								

						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Histórico de pagamento</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"200px"}} component="th" scope="row">
									  Histórico <br />
									  </TableCell>
								  <TableCell align="left">

								   {paymentHistory.length > 0 &&
									<Timeline style={{width:"50%"}} value={paymentHistory} opposite={paymentTimelineTemplate} content={(item) => <small className="p-text-secondary">{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</small>} />
								   }
								   
								   {paymentHistory.length == 0 &&
									<span>Não há histórico de pagamento para esse pedido.</span>
								   }
																		
								  
								  </TableCell>
								</TableRow>
								
							
							</TableBody>
						  </Table>
						</TableContainer>								

						{/* Detalhes da cobrança */}
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Detalhes da cobrança 
								{serviceOrder.status == 99 &&
									<span className="product-badge status-bloqueado" style={{marginLeft:"15px"}}>Cobrança cancelada</span>
								}
								</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

								<TableRow >
								  <TableCell style={{width:"300px"}} component="th" scope="row">Valor do Serviço</TableCell>
								  <TableCell align="left">{formatter.format(serviceOrder.ServiceBasisPrice)}</TableCell>
								</TableRow>
								
								{serviceOrder.type === 3 &&
									<TableRow >
									  <TableCell style={{width:"300px"}} component="th" scope="row">Taxa de Urgência</TableCell>
									  <TableCell align="left"><span className="product-badge status-bloqueado">{formatter.format(serviceOrder.ServiceUrgentFee)}</span></TableCell>
									</TableRow>	
								}								
			
								{serviceOrder.ServiceDiscount > 0 &&
									<TableRow >
									  <TableCell component="th" scope="row">Desconto (crédito, promoção etc)</TableCell>
									  <TableCell align="left">{formatter.format(serviceOrder.ServiceDiscount)}</TableCell>
									</TableRow>
								}

								<TableRow >
								  <TableCell component="th" scope="row" style={{fontWeight:"bold"}}>Valor Total</TableCell>
								  <TableCell align="left" style={{fontWeight:"bold"}}>{formatter.format(serviceOrder.ServiceTotalPrice)}</TableCell>
								</TableRow>		
		
							</TableBody>
						  </Table>
						</TableContainer>		
						
						
						{/* Detalhes da cobrança de taxa de penalidade */}
						{penaltyTaxes.length > 0 &&
						<>						
						
						<TableContainer component={Box} mt={2}>
						  <Table style={{minWwidth:"500px"}} aria-label="simple table">

							<TableHead>
							  <TableRow style={{backgroundColor:"#EEE"}}>
								<TableCell colSpan={3}>Cobrança de taxa de penalidade por cancelamento ou alteração</TableCell>
							  </TableRow>
							</TableHead>

							<TableBody>

										{penaltyTaxes.map( (item,index) => {

											var fieldLabel = item.history;
											
											if (item.valueType == "B" || item.valueType == "C" || item.valueType == "D") {
												var fieldValue = formatter.format(item.value);
											} // end if
											else {
												var fieldValue = item.value+"%";
											} // end else
												
										    var taxOrigin = item.InstanceType == "customer" ? "Cliente" : "Prestador";

											return (
											<>
											<TableRow >
											  <TableCell component="th" scope="row" colspan="2"><strong>Cobrança de taxa #{index+1}</strong></TableCell>
											</TableRow>		

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Origem</TableCell>
											  <TableCell align="left">{taxOrigin}</TableCell>
											</TableRow>	

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Descrição</TableCell>
											  <TableCell align="left">{item.memo}</TableCell>
											</TableRow>	

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Taxa cobrada</TableCell>
											  <TableCell align="left">{formatter.format(item.value)}</TableCell>
											</TableRow>							

											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Data da cobrança</TableCell>
											  <TableCell align="left">{moment(item.createdAt).format("DD/MM/YYYY hh:mm")}</TableCell>
											</TableRow>
											
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">Motivo da cobrança</TableCell>
											  <TableCell align="left">
											  
											  {item.penaltyType == 2 &&
											  <>
												<span className="product-badge status-encerrado">Alteração do pedido</span> <small> muito em cima da hora (faltando menos de 1h e 30min do horário de execução)</small>
											  </>
											  }
											  
											  {item.penaltyType == 1 &&
											  <>
												<span className="product-badge status-bloqueado">Cancelamento do pedido</span> <small>muito em cima da hora (faltando menos de 1h e 30min do horário de execução)</small>
											  </>
											  }											  
											  
											  </TableCell>
											</TableRow>		
											</>
											)}	

										 )}	

		
							</TableBody>
						  </Table>
						</TableContainer>
						</>
						}		

	
					  </CardBody>
					</Card>	

			</TabPanel>	

			<TabPanel value={value} index={2}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Comissões / Split</h4>
					  </CardHeader>
					  <CardBody>	  
				 								
						{serviceOrder.platformTotals &&							
								<>
								<TableContainer component={Box} mt={2}>
									  <Table style={{minWwidth:"500px"}} aria-label="simple table">

										<TableHead>
										  <TableRow style={{backgroundColor:"#EEE"}}>
											<TableCell colSpan={3}>Comissão Plataforma</TableCell>
										  </TableRow>
										</TableHead>

										<TableBody>

										{serviceOrder.platformTotals.map( (item) => {

											var fieldLabel = item.history;
											
											if (item.valueType == "B" || item.valueType == "C" || item.valueType == "D") {
												var fieldValue = formatter.format(item.value);
											} // end if
											else {
												var fieldValue = item.value+"%";
											} // end else

											return (
											<>
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">{fieldLabel}</TableCell>
											  <TableCell align="left" style={{fontWeight:item.valueType == "B" ? "bold" : "normal",color:item.valueType == "C" ? "green" : "#000"}}>{fieldValue}</TableCell>
											</TableRow>	
											</>
											)}	

										 )}											
					
										</TableBody>
									  </Table>
									</TableContainer>
								</>
							}
									
						{serviceOrder.providerTotals &&							
								<>
								<TableContainer component={Box} mt={2}>
									  <Table style={{minWwidth:"500px"}} aria-label="simple table">

										<TableHead>
										  <TableRow style={{backgroundColor:"#EEE"}}>
											<TableCell colSpan={3}>Comissão Prestador</TableCell>
										  </TableRow>
										</TableHead>

										<TableBody>

										{serviceOrder.providerTotals.map( (item) => {

											var fieldLabel = item.history;
											
											if (item.valueType == "B" || item.valueType == "C" || item.valueType == "D") {
												var fieldValue = formatter.format(item.value);
											} // end if
											else {
												var fieldValue = item.value+"%";
											} // end else

											return (
											<>
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">{fieldLabel}</TableCell>
											  <TableCell align="left" style={{fontWeight:item.valueType == "B" ? "bold" : "normal",color:item.valueType == "C" ? "green" : item.valueType == "D" ? "red" : "#000"}}>{fieldValue}</TableCell>
											</TableRow>	
											</>
											)}	

										 )}											
					
										</TableBody>
									  </Table>
									</TableContainer>
								</>
							}									
								
						{serviceOrder.partnerTotals &&							
								<>
								<TableContainer component={Box} mt={2}>
									  <Table style={{minWwidth:"500px"}} aria-label="simple table">

										<TableHead>
										  <TableRow style={{backgroundColor:"#EEE"}}>
											<TableCell colSpan={3}>Comissão Parceiro</TableCell>
										  </TableRow>
										</TableHead>

										<TableBody>

										{serviceOrder.partnerTotals.map( (item) => {

											var fieldLabel = item.history;
											
											if (item.valueType == "B" || item.valueType == "C" || item.valueType == "D") {
												var fieldValue = formatter.format(item.value);
											} // end if
											else {
												var fieldValue = item.value+"%";
											} // end else

											return (
											<>
											<TableRow >
											  <TableCell style={{width:"300px"}} component="th" scope="row">{fieldLabel}</TableCell>
											  <TableCell align="left" style={{fontWeight:item.valueType == "B" ? "bold" : "normal",color:item.valueType == "C" ? "green" : item.valueType == "D" ? "red" : "#000"}}>{fieldValue}</TableCell>
											</TableRow>	
											</>
											)}	

										 )}											
					
										</TableBody>
									  </Table>
									</TableContainer>
								</>
							}		

					  </CardBody>
					</Card>	

			</TabPanel>	

			<TabPanel value={value} index={3}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Problemas relatados</h4>
					  </CardHeader>
					  <CardBody>	  
				 		
						CARDS

					  </CardBody>
					</Card>	

			</TabPanel>	

			<TabPanel value={value} index={4}>

					<Card>
					  <CardHeader color="info" icon>
						<CardIcon color="info">
						  <Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Reclamações ou denúncias</h4>
					  </CardHeader>
					  <CardBody>	  
										
						CARDS 
										
					  </CardBody>
					</Card>	

			</TabPanel>	
	
		
			<Box ml={3}> 

				{serviceOrder.status != "9" &&
				<>
					{/*<Button variant="contained"
					onClick={() => alert("Em desenvolvimento...") }
					 >
					  Editar Cadastro
					</Button>*/}
				</>
				}

					<ApproveDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						serviceOrder={serviceOrder}
						history={history}
					/>

					<RepproveDialog 
						dialogAlert={dialogAlert}
						setAlertDialog={setAlertDialog}
						dialogTitle={dialogTitle}
						dialogMessage={dialogMessage}
						setDialogTitle={setDialogTitle}
						setDialogMessage={setDialogMessage}
						setDialogAction={setDialogAction}
						serviceOrder={serviceOrder}
						history={history}
					/>

			</Box>
	   
	  </GridItem>
	  
    </GridContainer>
	</>
  );
} // end if

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading,  
  appLoading:store.providerReducer.appLoading,
  dialog:store.providerReducer.dialog,
  providersApproval:store.providerReducer.providersApproval,
  providerApproval:store.providerReducer.providerApproval
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ServiceActions }, dispatch);


// Transition Slide for Dialog SLIDE UP	
const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;  
});	

/* APPROVE Dialog Component START */
export const ApproveDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
const useStyles = makeStyles((theme) => ({
  appBar: {
    
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
   display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
const classes = useStyles();	

// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	approveProvider
} = props;	


/* Dialog END*/	

const [loading, setLoading] = React.useState(false);

const confirm = (props) => {




};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


// useEffect only for dialgo changes
React.useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'approveProvider' && dialog.status) {
		

	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		 <Dialog maxWidth="sm" open={dialog.target == 'approveProvider' && dialog.status} onClose={()=>setDialog('approveProvider',false)} TransitionComponent={TransitionSlideUp}>

		   <DialogTitle id="customized-dialog-title" onClose={()=>setDialog('approveProvider',false)}>

			<IconButton aria-label="close" className={classes.closeButton} onClick={()=>setDialog('approveProvider',false)}>
			  <CloseIcon />
			</IconButton>

			  Aprovando cadastro de prestador
			</DialogTitle>

			<DialogContent dividers style={{padding:0}}>
			<Box style={{...screenStyleDialog}} p={0}>
			
				<Box mt={0} ml={1} mr={1}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>

								<h5 style={{fontSize:"1.2rem",color:"#A255A0",marginTop:"10px",textAlign:"center"}}>Aprovação de cadastro</h5>
								
								<Box mt={0}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"2rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> Atenção, ao aprovar o cadastro o Prestador será notificado e poderá acessar a plataforma. Quer mesmo aprovar?  </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* URGENT Date */}						
							
					<div>						
						<Box mt={2}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
						
							Antes de aprovar o cadastro, verifique todas as informações enviadas, incluindo os documentos anexos. 

						</Box>								
					</div>
			
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loading} color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('approveProvider',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loading}  variant="contained" style={{flex:2,height:"48px",color:"#FFF",backgroundColor:"green"}} onClick={()=>confirm(props)}>
							{ !loading &&
							   <div>Confirmar Aprovação</div>
							}
							
							{ loading &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Aprovando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>
		  </DialogContent>

		  </Dialog>
	 
		 
		</div>		
	);
	
});


/* REPPROVE Dialog Component START */
export const RepproveDialog = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {
	
const useStyles = makeStyles((theme) => ({
  appBar: {
    
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const screenStyleDialog = {
  alignItems: "stretch",
  justifyContent: "top",
   display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF"
}; 
	
// set classes var
const classes = useStyles();	

// set vars from props
// Remember to add those who come from Redux
const { 
	setDialog,
	dialog,
	repproveProvider
} = props;	


/* Dialog END*/	

const [loading, setLoading] = React.useState(false);
const [repproveReason, setRepproveReason] = React.useState("-");

const confirm = (props) => {




};	

var formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});


console.log("dialog >>>>>>>>>>>>>>>>>>>: "+JSON.stringify(dialog,null,2));


// useEffect only for dialgo changes
React.useEffect(() => {
		
	// execute only if this modal
	if (dialog.target == 'repproveProvider' && dialog.status) {
		

	
	}  // end if

}, [dialog]); // end useEffect only for dialgo changes

	return (
	<div>
		 <Dialog maxWidth="sm" open={dialog.target == 'repproveProvider' && dialog.status} onClose={()=>setDialog('repproveProvider',false)} TransitionComponent={TransitionSlideUp}>

		   <DialogTitle id="customized-dialog-title" onClose={()=>setDialog('repproveProvider',false)}>

			<IconButton aria-label="close" className={classes.closeButton} onClick={()=>setDialog('repproveProvider',false)}>
			  <CloseIcon />
			</IconButton>

			  Reprovando cadastro de prestador
			</DialogTitle>

			<DialogContent dividers style={{padding:0}}>
			<Box style={{...screenStyleDialog}} p={0}>
			
				<Box mt={0} ml={1} mr={1}>
					
					<Box style={{marginBottom:"5px",alignItems:"stretch",justifyContent:"flex-start",display:"flex",flexDirection:"column"}} p={0}>

								<h5 style={{fontSize:"1.2rem",color:"#A255A0",marginTop:"10px",textAlign:"center"}}>Reprovação de cadastro</h5>
								
								<Box mt={0}  mb={1} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
										<div style={{marginRight:"10px"}}> 
											<WarningIcon style={{fontSize:"2rem",color:"red"}} />
										</div>
										
										<div> 
											<div style={{marginBottom:"10px",textAlign:"left"}}> Atenção, ao reprovar o cadastro o Prestador será notificado sobre a reprovação de seu cadastro. Quer mesmo reprovar?  </div>
										</div>
								</Box>									
							
					</Box>
					
					<Divider style={{marginBottom:"5px"}} />
					
					{/* REASON REPPROVE Date */}						

						<Box mt={2}  mb={0} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
								<div> 
									<div style={{marginBottom:"10px",textAlign:"center",fontWeight:"bold"}}> Selecione o motivo da reprovação: </div>
								</div>
						</Box>	
						
						<Box mt={0}  mb={2} ml={2} mr={2} style={{alignItems:"center",justifyContent:"flex-start",display:"flex",flexDirection:"row"}}>  
								<div style={{marginRight:"10px",width:"90%"}}> 

										<Select
										  label="Motivo do encerramento"
										  placeholder="Motivo do encerramento"
										  style={{ width:"100%",paddingTop:0,paddingBottom:0,margin:5,marginLeft:10,marginRight:0,backgroundColor:"#fff",width:"100%"}}
										  size="small"
										  variant="outlined"
										  value={repproveReason}
										  onChange={(event) => {
											  setRepproveReason(event.target.value)
										  }}	
										 >
											<MenuItem value="-">- Selecione um motivo -</MenuItem>
											<MenuItem value="Informações fornecidas são inválidas">Informações fornecidas são inválidas</MenuItem>
											<MenuItem value="Documentos anexos inválidos">Documentos anexos inválidos</MenuItem>
											<MenuItem value="Não tem experiência ou formação necessários">Não tem experiência ou formação necessários</MenuItem>
											<MenuItem value="Outro">Outro motivo não listado</MenuItem>
										</Select>
										
								</div>

						</Box>	
			
			
					<Divider style={{marginBottom:"5px"}} />
						
					<Box style={{display:"flex",flexDirection:"row",justifyContent:"center"}} ml={1} mr={1} mb={5} mt={2}>						
						
						<Button disabled={loading} color="secondary" variant="outlined" style={{flex:1,height:"48px",marginRight:"10px"}} onClick={()=>setDialog('repproveProvider',false)}>
						 Fechar
						</Button>		
						
						<Button disabled={loading}  variant="contained" style={{flex:2,height:"48px",color:"#FFF",backgroundColor:"red"}} onClick={()=>confirm(props)}>
							{ !loading &&
							   <div>Confirmar Reprovação</div>
							}
							
							{ loading &&
							<>
							   <CircularProgress size={30} color="inherit" />
							   <span style={{marginLeft:"10px"}}>Reprovando...</span>
							</>
							}
						</Button>
							
					</Box>			
			
				</Box>				
			
			</Box>
		  </DialogContent>

		  </Dialog>
	 
		 
		</div>		
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ServiceOrderView);
	

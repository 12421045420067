import React,{useState,useRef} from "react";
import {useHistory,Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// @material-ui/core components
import './DataTable.css';
import { makeStyles,lighten } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// import { DataGrid,GridToolbar,ptBR  } from '@material-ui/data-grid';
import moment from "moment";

// Import Prime React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

// Add Locale
addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
});

locale('pt-BR');

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import View from '@material-ui/icons/Visibility';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Load Actions
import * as AppActions from 'Store/Actions/App'
import * as ProviderActions from 'Store/Actions/Provider'

const useStyles = makeStyles(styles);

const ProvidersApproval = (props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
} = props;		


const [value, setValue] = React.useState(0);
const [contentVisible, setContentVisible] = React.useState(false); 

// console.log("[providers]: "+JSON.stringify(providers,null,2));
	
const classes = useStyles();

// Handle Tab changes
const handleChange = (event, newValue) => {
	
// Check which TAB was picked
//
		
setValue(newValue);

};

 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// set Use of history for PUSH 
let history = useHistory();

// Tasks to execute when mount component
React.useEffect(() => {
	
	setTimeout(function() {
			
		setAppLoading(false);
		setContentVisible(true);		
		
	},1000);

    return () => {
     setAppLoading(true);
    };	
	
}, []);

	
if(!contentVisible) {	  
	return (
		<div style={{width:"100%",height:"80vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
		
			<div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
			
				<CircularProgress color="primary" />
				<div style={{fontSize:"18px",marginLeft:"15px"}}> Carregando dados...</div>
			
			</div>
		
		</div>
	)
  }

if(contentVisible) {
  return ( 
  <>	
   <Box ml={3} mb={5}>
   
	 <Breadcrumbs aria-label="breadcrumb">
	  <Typography color="textSecondary">Gestão de Prestadores</Typography>
	  <Typography color="textPrimary">Aprovação de Cadastros</Typography>
	</Breadcrumbs>
	
	<Divider />
	
	<h3 style={{marginTop:"30px"}}>Cadastros aguardando análise</h3>
	<h5>Para aprovar ou reprovar o cadastro, aperte em ver detalhes. Os cadastros abaixo estão ordenados por ordem de envio, do mais antigo para mais novo.</h5>	
		
	</Box>
  
    <GridContainer>
	
      <GridItem xs={12}>
	  
		  <Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
		  >
			<Tab label="Aguardando análise" />
			<Tab label="Reprovados" />
		  </Tabs>
     	  <Divider />
		  
			<TabPanel value={value} index={0}>
			
					<Tab1Content
						setAppLoading={setAppLoading}
						setContentVisible={setContentVisible}
					/>
					
			</TabPanel>
				  
			<TabPanel value={value} index={1}>
										
					<Tab2Content
						setAppLoading={setAppLoading}
						setContentVisible={setContentVisible}
					/>
					
			</TabPanel>		
	   
	  </GridItem>
	  
    </GridContainer>
	</>
  );
}

return (null);

}

const mapStateToProps = store => ({
  buttonLoading:store.appReducer.buttonLoading
 });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AppActions, ...ProviderActions }, dispatch);


// Tab1Content Object
export const Tab1Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveApprovalProviders
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [providersRows, setProvidersRows] = useState([]);

// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of Providers
	retrieveApprovalProvidersAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}

// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}  
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Prestadores em análise </h5>
	</div>
);	

// Column Name Body Template  
const nameBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Nome Empresa</span>
			{rowData.ProviderProfile.ProviderProfileCompany.realName}
		</React.Fragment>
	);
}  

// Column Email Body Template    
const emailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </React.Fragment>
        );
    }
						
// Column Email Body Template    
const contactNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Nome Contato</span>
                {rowData.ProviderProfile.ProviderProfilePerson.completeName}
            </React.Fragment>
        );
    }
						
// Column CNPJ Body Template  
const companyDocumentBodyTemplate = (rowData) => {
	
	// Clean and format
	var cleaned = ('' + rowData.ProviderProfile.ProviderProfileCompany.document).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/)
	  if (match) {
		var formattedDocument = '' + match[1] + '.' + match[2] + '.' + match[3] + '/' + match[4] + '-' + match[5];
	  } // end if
	  else {
		var formattedDocument = "CNPJ Inválido";
	  } // end else	  
	
	return (
		<React.Fragment>
			<span className="p-column-title">CNPJ</span>
			{formattedDocument}
		</React.Fragment>
	);
}

// Column CPF Body Template  
const documentBodyTemplate = (rowData) => {
	
	// Clean and format
	var cleaned = ('' + rowData.ProviderProfile.ProviderProfilePerson.personalDocument).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/)
	  if (match) {
		var formattedDocument = '' + match[1] + '.' + match[2] + '.' + match[3]+ '-' + match[4];
	  } // end if
	  else {
		var formattedDocument = "CPF Inválido";
	  } // end else	  
	
	return (
		<React.Fragment>
			<span className="p-column-title">CPF</span>
			{formattedDocument}
		</React.Fragment>
	);
}

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {
	
	if (rowData.status == 9) {
		var activationStatus = "BLOQUEADO";
		var activationStatusLabel = "Bloqueado";
	} // end if
	else {
		var activationStatus = "REVIEW";
		var activationStatusLabel = "Aguardando análise";
	} // end else
	 
	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span><br />
		{rowData.status == 1 && <small style={{color:"#666"}}> por {rowData.activationMethod}</small>}
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Detalhes" icon="pi pi-search" className="p-button-outlined p-button-primary p-button-sm" style={{margin:"3px"}} onClick={() => { history.push("/admin/approveprovider/"+rowData.id+"") }}  />
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByName && !filterByEmail && !filterByContactName && !filterByCompanyDocument && !filterByDocument && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.realName = filterByName ? filterByName:null;
	filters.email = filterByEmail ? filterByEmail:null;
	filters.completeName = filterByContactName ? filterByContactName:null;
	filters.document = filterByCompanyDocument ? filterByCompanyDocument:null;
	filters.personalDocument = filterByDocument ? filterByDocument:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	setFilterByName("");
	setFilterByEmail("");
	setFilterByContactName("");
	setFilterByCompanyDocument("");
	setFilterByDocument("");
	setFilterByCreatedAt("");
	setFilterByStatus("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons

	// Name Filter
	const nameFilter = <InputText style={{width:"100%"}} type="search" value={filterByName} onChange={(e) => setFilterByName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;	
	
	// Email Filter
	const emailFilter = <InputText style={{width:"100%"}}  type="search" value={filterByEmail} onChange={(e) => setFilterByEmail(e.target.value)} className="p-column-filter" placeholder="Filtrar por email" />;	
	
	// Phone Filter
	const contactNameFilter = <InputText style={{width:"100%"}}  type="search" value={filterByContactName} onChange={(e) => setFilterByContactName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;		
	
	// Company Document
	const companyDocumentFilter = <InputText style={{width:"100%"}}  type="search" value={filterByCompanyDocument} onChange={(e) => setFilterByCompanyDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CNPJ" />;

	// Personal Document
	const documentFilter = <InputText style={{width:"100%"}}  type="search" value={filterByDocument} onChange={(e) => setFilterByDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CPF" />;	
	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por data"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"BLOQUEADO","label":"Bloqueado","code":3},{"id":"ENCERRADO","label":"Encerrado","code":9}];

	// Activation Status Filter
	const statusFilter = <Dropdown style={{width:"100%"}}  value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Status" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Filtrar" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		  

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveApprovalProvidersAction = () => {

	var data = {
		status:[0],
		...lazyParams
	}
	var propsParam = {
		setProvidersRows:setProvidersRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	retrieveApprovalProviders(data,propsParam);

} // end 

// set Use of history for PUSH 
let history = useHistory();
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Cadastros aguardando análise</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
							
						{/*<Toolbar style={{marginBottom:"4px"}} left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>*/}
							
						<DataTable ref={dt} value={providersRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhum prestador aguardando análise." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
							
							<Column field="ProviderProfile.ProviderProfileCompany.realName" header="Empresa" body={nameBodyTemplate} sortable filter filterPlaceholder="Filtrar por nome" sortable filterElement={nameFilter} />
							
							<Column field="ProviderProfile.ProviderProfileCompany.document" header="CNPJ" body={companyDocumentBodyTemplate} filter filterPlaceholder="Filtrar por CNJP" filterElement={companyDocumentFilter} />
							
							<Column field="ProviderProfile.ProviderProfilePerson.completeName" header="Nome Contato" body={contactNameBodyTemplate} sortable filter filterPlaceholder="Filtrar por nome" sortable filterElement={contactNameFilter} />
							
							<Column field="ProviderProfile.ProviderProfilePerson.personalDocument" header="CPF" body={documentBodyTemplate} filter filterPlaceholder="Filtrar por CPF" filterElement={documentFilter} />
							
							{/*<Column field="email" header="Email" body={emailBodyTemplate} filter filterPlaceholder="Filtrar por email" filterElement={emailFilter} />*/}
							
							<Column field="createdAt" header="Criado em" body={createdBodyTemplate} sortable filter filterElement={dateFilter} filterFunction={filterDate} />

							<Column header="Status" body={statusBodyTemplate} filterElement={statusFilter} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
							
						</DataTable>						
						
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<Dialog header={alertTitle} visible={alert} style={{ width: '30vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog>			
	</>	
	);
	
});


// Tab2Content Object
export const Tab2Content = connect(
		mapStateToProps,
		mapDispatchToProps
	)((props) => {

// set vars from props
const { 
	appLoading,
	setAppLoading,
	retrieveProviders
} = props;		

// Set classes
const classes = useStyles();	

// useRef
var dt = useRef(null);

// Scope data
const [providersRows, setProvidersRows] = useState([]);

// Filter fields
const [filterByName, setFilterByName] = useState("");
const [filterByEmail, setFilterByEmail] = useState("");
const [filterByContactName, setFilterByContactName] = useState("");
const [filterByCompanyDocument, setFilterByCompanyDocument] = useState("");
const [filterByDocument, setFilterByDocument] = useState("");
const [filterByCreatedAt, setFilterByCreatedAt] = useState("");
const [filterByStatus, setFilterByStatus] = useState(null); 

// Dialog control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// DataGrid Params
const [loading, setLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
		filters:null,
		sortField:"createdAt",
		sortOrder:1
    });

React.useEffect(() => {

	loadLazyData();

},[lazyParams]);
 
const loadLazyData = () => {
	
	setLoading(true);
	
	// Retrieve List of Providers
	retrieveApprovalProvidersAction();
}

const onPage = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onSort = (event) => {	
	let _lazyParams = { ...lazyParams, ...event };
	setLazyParams(_lazyParams);
}

const onFilter = (event) => {
	let _lazyParams = { ...lazyParams, ...event };
	_lazyParams['first'] = 0;
	setLazyParams(_lazyParams);
}
  
// ToolBar Content  
const leftToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}

const rightToolbarTemplate = () => {
	return (
		<React.Fragment>
			
		</React.Fragment>
	)
}    
  
// Header Template  
const header = (
	<div className="table-header" style={{height:"40px"}}>
		<h5 style={{float:"left"}}> Prestadores reprovados </h5>
	</div>
);	
  
// Column Name Body Template  
const nameBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Nome Empresa</span>
			{rowData.ProviderProfile.ProviderProfileCompany.realName}
		</React.Fragment>
	);
}  

// Column Email Body Template    
const emailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </React.Fragment>
        );
    }
						
// Column Email Body Template    
const contactNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Nome Contato</span>
                {rowData.ProviderProfile.ProviderProfilePerson.completeName}
            </React.Fragment>
        );
    }
					
// Column CNPJ Body Template  
const companyDocumentBodyTemplate = (rowData) => {
	
	// Clean and format
	var cleaned = ('' + rowData.ProviderProfile.ProviderProfileCompany.document).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/)
	  if (match) {
		var formattedDocument = '' + match[1] + '.' + match[2] + '.' + match[3] + '/' + match[4] + '-' + match[5];
	  } // end if
	  else {
		var formattedDocument = "CNPJ Inválido";
	  } // end else	  
	
	return (
		<React.Fragment>
			<span className="p-column-title">CNPJ</span>
			{formattedDocument}
		</React.Fragment>
	);
}

// Column CPF Body Template  
const documentBodyTemplate = (rowData) => {
	
	// Clean and format
	var cleaned = ('' + rowData.ProviderProfile.ProviderProfilePerson.personalDocument).replace(/\D/g, '')
	  var match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/)
	  if (match) {
		var formattedDocument = '' + match[1] + '.' + match[2] + '.' + match[3]+ '-' + match[4];
	  } // end if
	  else {
		var formattedDocument = "CPF Inválido";
	  } // end else	  
	
	return (
		<React.Fragment>
			<span className="p-column-title">CPF</span>
			{formattedDocument}
		</React.Fragment>
	);
}

// Column CreatedAt Body Template  
const createdBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<span className="p-column-title">Criado em</span>
			{moment(rowData.createdAt).format("DD/MM/YYYY")}
		</React.Fragment>
	);
}	

// Column Activation Status Body Template  
const statusBodyTemplate = (rowData) => {

	var activationStatus = "BLOQUEADO";
	var activationStatusLabel = "Reprovado";

	return (
	<>
	<span className={`product-badge status-${activationStatus.toLowerCase()}`}>{activationStatusLabel}</span><br />
		{rowData.status == 1 && <small style={{color:"#666"}}> por {rowData.activationMethod}</small>}
	</>
	)
}	

// Template for Activation Status DropDown List
const statusItemTemplate = (option) => {	
	return <span className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>;
}	

// Column Actions Body Template  
// NEED TO ADD ROW ID AND ONCLICK EVENT
const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button label="Detalhes" icon="pi pi-search" className="p-button-outlined p-button-primary p-button-sm" style={{margin:"3px"}} onClick={() => { history.push("/admin/approveprovider/"+rowData.id+"") }}/>
		</React.Fragment>
	);
}	

const filterDate = (value, filter) => {
	if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return value === formatDate(filter);
}

const formatDate = (date) => {
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) {
		month = '0' + month;
	}

	if (day < 10) {
		day = '0' + day;
	}

	return date.getFullYear() + '-' + month + '-' + day;
}		

// Apply Filter
const applyFilterAction = () => {
	
	// Check if any of the filters are not equal to NULL
	if (!filterByName && !filterByEmail && !filterByContactName && !filterByCompanyDocument && !filterByDocument && !filterByCreatedAt && !filterByStatus) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para aplicar o filtro você tem que ao menos selecionar ou preencher algum item para filtrar.");
		setAlert(true);
		return;
	} // end if
	
	var filters = {};
	filters.realName = filterByName ? filterByName:null;
	filters.email = filterByEmail ? filterByEmail:null;
	filters.completeName = filterByContactName ? filterByContactName:null;
	filters.document = filterByCompanyDocument ? filterByCompanyDocument:null;
	filters.personalDocument = filterByDocument ? filterByDocument:null;
	filters.createdAt = filterByCreatedAt ? filterByCreatedAt:null;
	filters.status = filterByStatus ? filterByStatus.code:null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);
	
}

// Reset Filter
const resetFilterAction = () => {
	
	setFilterByName("");
	setFilterByEmail("");
	setFilterByContactName("");
	setFilterByCompanyDocument("");
	setFilterByDocument("");
	setFilterByCreatedAt("");
	setFilterByStatus("");
	setFilterByStatus(null);
	
	var filters = null;
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyParams, filters:filters };
	setLazyParams(_lazyParams);	
	
	// Load Data from service with defautl option
	// ...
}

// Filter fields and action buttons

	// Name Filter
	const nameFilter = <InputText style={{width:"100%"}} type="search" value={filterByName} onChange={(e) => setFilterByName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;	
	
	// Email Filter
	const emailFilter = <InputText style={{width:"100%"}} type="search" value={filterByEmail} onChange={(e) => setFilterByEmail(e.target.value)} className="p-column-filter" placeholder="Filtrar por email" />;	
	
	// Phone Filter
	const contactNameFilter = <InputText style={{width:"100%"}} type="search" value={filterByContactName} onChange={(e) => setFilterByContactName(e.target.value)} className="p-column-filter" placeholder="Filtrar por nome" />;		
	
	// Company Document
	const companyDocumentFilter = <InputText style={{width:"100%"}} type="search" value={filterByCompanyDocument} onChange={(e) => setFilterByCompanyDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CNPJ" />;

	// Personal Document
	const documentFilter = <InputText style={{width:"100%"}} type="search" value={filterByDocument} onChange={(e) => setFilterByDocument(e.target.value)} className="p-column-filter" placeholder="Filtrar por CPF" />;	
	
	// CreatedAt Filter
	const dateFilter = <Calendar locale="pt-BR" showButtonBar value={filterByCreatedAt} onChange={(e) => setFilterByCreatedAt(e.target.value)} dateFormat="dd/mm/yy" className="p-column-filter" placeholder="Filtrar por data"/>;	

	// Set Status Dropdown Content
	const statuses = [{"id":"BLOQUEADO","label":"Bloqueado","code":3},{"id":"ENCERRADO","label":"Encerrado","code":9}];

	// Activation Status Filter
	const statusFilter = <Dropdown style={{width:"100%"}} value={filterByStatus} options={statuses} onChange={(e) => setFilterByStatus(e.target.value)} itemTemplate={statusItemTemplate} placeholder="Selecione o Status" className="p-column-filter" showClear />;

	// Action Buttons 
	const actionFilter = <><Button label="Filtrar" icon="pi pi-check" className="p-button-outlined p-button-primary" onClick={() => applyFilterAction()} style={{marginRight:"5px"}} /> {lazyParams.filters && <Button title="Limpar" icon="pi  pi-filter-slash" className=" p-button-info p-button-outlined" onClick={() => resetFilterAction()} />}</>
		

// Dialog Footer
const renderFooter = () => {
	return (
		<div>
			<Button label="OK" icon="pi pi-check" onClick={() => setAlert(false)} />
		</div>
	);
}		
		
// Tasks to execute when mount component
React.useEffect(() => {

	// on component mount
	//console.log("[MOUNTED COMPONENT...]--->");

    return () => {
     setAppLoading(true);
    };			
}, []);

		
const retrieveApprovalProvidersAction = () => {

	var data = {
		status:[4],
		...lazyParams
	}
	var propsParam = {
		setProvidersRows:setProvidersRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}	
	retrieveProviders(data,propsParam);

} // end 
	
// set Use of history for PUSH 
let history = useHistory();	
	
	return (
	<>
		<Card>
			  <CardHeader color="info" icon>
				<CardIcon color="info">
				  <Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Cadastros reprovados</h4>
			  </CardHeader>
	
			  <CardBody>	  

				<div className="datatable-responsive-demo" style={{marginTop:"20px"}}>
				
					<div className="card">
					
					{/*<Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>*/}
					
						<DataTable ref={dt} value={providersRows} className="p-datatable-responsive-demo" rows={lazyParams.rows} header={header} paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}" rowsPerPageOptions={[10,20,50]} emptyMessage="Nenhum prestador reprovado." lazy paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={console.log("filtering...")} filters={lazyParams.filters} loading={loading} >
							
							<Column field="ProviderProfile.ProviderProfileCompany.realName" header="Empresa" body={nameBodyTemplate} sortable filter filterPlaceholder="Filtrar por nome" sortable filterElement={nameFilter} />
							
							<Column field="ProviderProfile.ProviderProfileCompany.document" header="CNPJ" body={companyDocumentBodyTemplate} filter filterPlaceholder="Filtrar por CNJP" filterElement={companyDocumentFilter} />
							
							<Column field="ProviderProfile.ProviderProfilePerson.completeName" header="Nome Contato" body={contactNameBodyTemplate} sortable filter filterPlaceholder="Filtrar por nome" sortable filterElement={contactNameFilter} />
							
							<Column field="ProviderProfile.ProviderProfilePerson.personalDocument" header="CPF" body={documentBodyTemplate} filter filterPlaceholder="Filtrar por CPF" filterElement={documentFilter} />
							
							{/*<Column field="email" header="Email" body={emailBodyTemplate} filter filterPlaceholder="Filtrar por email" filterElement={emailFilter} />*/}
							
							<Column field="createdAt" header="Criado em" body={createdBodyTemplate} sortable filter filterElement={dateFilter} filterFunction={filterDate} />

							<Column header="Status" body={statusBodyTemplate} filterElement={statusFilter} />
							
							<Column body={actionBodyTemplate} filter filterElement={actionFilter} ></Column>
							
						</DataTable>
						
					</div>
				</div>
								
			  </CardBody>
			</Card>	
			
			<Dialog header={alertTitle} visible={alert} style={{ width: '30vw' }} footer={renderFooter()} onHide={() => setAlert(false)} draggable={false} dismissableMask={true} closable={false} >
				<p>{alertBody}</p>
			</Dialog>			
	</>	
	);
	
});


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ProvidersApproval);	

import * as types from '../actionTypes';

const initialState = { 
  buttonLoading: true,
  providersApproval: [],
  providerApproval: {},
  appLoading: true,	
  dialog:{},
}


export const providerReducer = (state = initialState, action) => {
		
    switch (action.type) {
		case types.APP_LOADING:
			return { ...state, appLoading: action.payload };
        case types.SET_DIALOG:
            return { ...state, dialog: action.payload }	
		case types.SET_PROVIDERS:
			return { ...state,providersApproval: action.payload };
		case types.SET_PROVIDER:
			return { ...state,providerApproval: action.payload };
        default:
            return state
    }
}
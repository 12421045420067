import axios from 'axios';
import * as types from '../actionTypes';
import jwt_decode from "jwt-decode";
import * as config from '../../Config/api.js';

	
export const setInnerLoading  = (loadingStatus) => { 
	return { 
		type: types.INNER_LOADING,
		payload: loadingStatus 
	}
};

export const setDialog  = (target,status) => { 
		
	/* Logica */
		// ...
	
	return { 
		type: types.SET_DIALOG,
		payload: {target:target,status:status} 
	}
};




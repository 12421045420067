import { appReducer } from './appReducer';
// import { loginReducer } from './loginReducer';
// import { managerReducer } from './managerReducer';
import { serviceReducer } from './serviceReducer';
import { providerReducer } from './providerReducer';
import { customerReducer } from './customerReducer';
// import { supportReducer } from './supportReducer';

import { combineReducers } from 'redux';

export const Reducers = combineReducers({
  appReducer: appReducer,
  providerReducer:providerReducer,
  customerReducer:customerReducer,
  serviceReducer:serviceReducer,
});

/*
  appReducer: appReducer,
  managerReducer:managerReducer,
  loginReducer:loginReducer,
  serviceReducer:serviceReducer,
  providerReducer:providerReducer,
  customerReducer:customerReducer,
  supportReducer:supportReducer
*/